/* Movie Theater Page Styles */
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&family=Cinzel:wght@400;700&display=swap');

.movies-body {
    background: linear-gradient(135deg, #3c0000 0%, #1a0000 50%, #300000 100%);
    color: #e0e0e0;
    margin: 0;
    padding: 0;
    min-height: 100vh;
}

.movies-body::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.03;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23ffffff' fill-opacity='0.15' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
    pointer-events: none;
    z-index: -1;
}

.movies-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-left: 200px;
    min-height: 100vh;
}

.movies-container h1 {
    text-align: center;
    margin-bottom: 20px;
    font-family: 'Cinzel', serif;
    font-weight: 700;
    color: #ffd700;
    text-shadow: 
        2px 2px 4px rgba(0, 0, 0, 0.5),
        -1px -1px 0 #800,
        1px -1px 0 #800,
        -1px 1px 0 #800,
        1px 1px 0 #800;
    letter-spacing: 1px;
    font-size: 2.5rem;
}

.movie-main-container {
    width: 853px;
    margin: 0 auto 20px auto;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.7);
    background-color: #000;
}

.movie-welcome {
    height: 480px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, #470000 0%, #250000 100%);
    color: #ffd700;
    text-align: center;
    padding: 20px;
}

.movie-welcome h2 {
    margin-bottom: 20px;
    font-family: 'Playfair Display', serif;
    font-weight: 700;
    text-shadow: 
        0 0 5px rgba(255, 215, 0, 0.7),
        -1px -1px 0 #600,
        1px -1px 0 #600,
        -1px 1px 0 #600,
        1px 1px 0 #600;
    letter-spacing: 1px;
    font-size: 2.2rem;
}

.movie-welcome p {
    font-family: 'Playfair Display', serif;
    font-size: 1.2rem;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
    letter-spacing: 0.5px;
}

.movie-player-container {
    position: relative;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    overflow: hidden;
}

.movie-player-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}

.theater-container {
    width: 100%;
}

.theater-canvas {
    display: block;
    width: 100%;
    height: 150px;
    margin: 0 auto;
}

.movie-selection-container {
    max-width: 853px;
    margin: 20px auto;
}

.movie-selection-container h3 {
    color: #ffd700;
    margin-bottom: 15px;
    border-bottom: 1px solid rgba(255, 215, 0, 0.3);
    padding-bottom: 5px;
    font-family: 'Cinzel', serif;
    font-weight: 700;
    text-shadow: 
        2px 2px 3px rgba(0, 0, 0, 0.5),
        -1px -1px 0 #600,
        1px -1px 0 #600,
        -1px 1px 0 #600,
        1px 1px 0 #600;
    letter-spacing: 1px;
    font-size: 1.8rem;
}

.movie-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.movie-item {
    display: flex;
    background-color: rgba(20, 0, 0, 0.5);
    border-radius: 6px;
    overflow: hidden;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    cursor: pointer;
    border: 1px solid rgba(255, 215, 0, 0.1);
    min-height: 110px; /* Increased minimum height */
}

.movie-item:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
    background-color: rgba(40, 0, 0, 0.5);
}

.movie-item.active {
    border-color: #ffd700;
    background-color: rgba(50, 0, 0, 0.7);
}

.movie-thumbnail {
    position: relative;
    width: 160px;
    height: 90px;
    flex-shrink: 0;
    overflow: hidden;
}

.movie-thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.movie-item:hover .movie-thumbnail img {
    transform: scale(1.1);
}

.play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffd700;
    opacity: 0.8;
    transition: all 0.2s;
}

.movie-item:hover .play-button {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.1);
    background-color: rgba(200, 0, 0, 0.7);
}

.movie-info {
    padding: 15px 20px; /* Increased horizontal padding */
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    overflow: hidden;
}

.movie-info h4 {
    margin: 0 0 8px 0;
    font-size: 16px;
    color: #ffffff;
    font-family: 'Playfair Display', serif;
    font-weight: 700;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
    letter-spacing: 0.5px;
}

.movie-description {
    margin: 0;
    font-size: 14px;
    color: #aaaaaa;
    line-height: 1.6; /* Increased line height */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Increased from 2 to 3 lines */
    -webkit-box-orient: vertical;
    font-family: Arial, sans-serif; /* Changed to a more readable sans-serif font */
    text-shadow: none; /* Removed text shadow for better readability */
    max-width: 100%;
}

.movie-item:hover .movie-description {
    color: #cccccc;
}

.movie-item.active .movie-description {
    color: #d6d6d6;
}

/* Media queries for responsive design */
@media (max-width: 1400px) {
    .movies-container {
        margin-left: 0;
    }
}

@media (max-width: 768px) {
    .movie-item {
        flex-direction: row;
    }
    
    .movie-thumbnail {
        width: 120px;
        height: 67px;
    }
    
    .play-button {
        width: 30px;
        height: 30px;
        font-size: 12px;
    }
    
    .movie-info h4 {
        font-size: 14px;
        margin-bottom: 4px;
    }
    
    .movie-description {
        font-size: 13px;
        -webkit-line-clamp: 3; /* Keep 3 lines even on smaller screens */
        line-height: 1.5;
    }
}

@media (max-width: 480px) {
    .movie-player-container {
        padding-top: 75%; /* Adjusted for smaller screens */
    }
    
    .theater-canvas {
        height: 100px;
    }
}
