/* Apply gradient to entire body */
.gallery-body {
    background: linear-gradient(90deg, #0a0a0a 0%, #1a1a1a 20%, #292929 50%, #1a1a1a 80%, #0a0a0a 100%);
    color: #e0e0e0; /* Light text color for better readability on dark background */
    margin: 0;
    padding: 0;
    min-height: 100vh;
}

/* Move the texture overlay to gallery-body */
.gallery-body::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.03; /* Very subtle */
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23ffffff' fill-opacity='0.15' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
    pointer-events: none;
    z-index: -1;
}

.gallery-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-left: 200px; /* Prevent overlap with navigation */
    min-height: 100vh;
}

/* Remove the duplicate ::before on gallery-container */
.gallery-container::before {
    content: none;
}

.order-controls {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.order-controls button {
    margin: 0 10px;
    padding: 8px 16px;
    background-color: #4a4a4a;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.order-controls button:hover {
    background-color: #666;
}

.order-controls button.active {
    background-color: #2a2a2a;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
}

.main-image-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    height: 780px; /* Fixed height, 30% bigger than before */
    max-height: 60vh;
}

.main-image {
    width: 1040px; /* Fixed width, 30% bigger than before (was 800px) */
    height: 100%; /* Fixed height, 30% bigger than before (was 600px) */
    object-fit: contain; /* Maintain aspect ratio without stretching */
    box-shadow: none; /* Removed drop shadow */
    cursor: pointer; /* Add pointer cursor to indicate it's clickable */
    transition: transform 0.2s ease; /* Smooth transform on hover */
}

.main-image:hover {
    transform: scale(1.01); /* Slightly enlarge on hover */
}

.order-controls > button {
    width: 150px;
}

.navigation-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.navigation-buttons button {
    margin: 0 10px;
    width: 150px;
    padding: 8px 16px;
    background-color: #4a4a4a;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.navigation-buttons button:hover {
    background-color: #666;
}

.image-date-location {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 10px;
}

.date, .location {
    font-style: italic;
    color: #666;
}

.image-description {
    min-height: 90px;
    max-width: 900px;
    text-align: left; /* Text is left-aligned within the box */
    font-size: 18px;
    margin-bottom: 0px; /* Reduced from 40px */
    padding: 0 20px;
    width: 60%; /* Increased width for description */
    margin-left: auto;
    margin-right: auto; /* Center the textbox */
}

.reshuffle-container {
    display: flex;
    justify-content: center;
    margin-bottom: 15px; /* Reduced from 30px to bring thumbnails closer */
}

.reshuffle-container button {
    padding: 8px 16px;
    background-color: #4a4a4a;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.reshuffle-container button:hover {
    background-color: #666;
}

.thumbnails-container {
    position: relative;
    width: 100%;
    height: 1000px; /* Reduced from 1600px for a more compact layout */
    overflow: visible; /* Allows thumbnails to be seen even if they overflow */
    margin-top: 0; /* Ensure no extra space at the top */
    padding-top: 0; /* Ensure no extra padding at the top */
}

.thumbnail {
    position: absolute;
    width: 120px;
    height: 90px;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    background-color: white;
    padding: 3px;
}

.thumbnail:hover {
    transform: scale(1.1) !important;
    z-index: 1000 !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Media queries for mobile devices */
@media (max-width: 768px) {
    /* Make all buttons bigger on mobile */
    .order-controls button,
    .navigation-buttons button,
    .reshuffle-container button {
        padding: 12px 24px;
        font-size: 16px;
        margin: 0 5px;
    }
    
    /* Ensure buttons have enough space between them */
    .order-controls,
    .navigation-buttons {
        gap: 10px;
    }
    
    /* Make sure controls are fully visible */
    .order-controls {
        flex-wrap: wrap;
        margin-bottom: 15px;
    }
    
    /* Adjust gallery container for mobile */
    .gallery-container {
        margin-left: 0;
        padding: 10px;
    }
    
    /* Ensure thumbnails are easier to tap */
    .thumbnail {
        transform: scale(1.2);
    }
}

/* Even smaller devices */
@media (max-width: 480px) {
    .order-controls button,
    .navigation-buttons button,
    .reshuffle-container button {
        padding: 14px 28px;
        font-size: 18px;
        margin: 5px;
    }
}
