@keyframes bgscroll {
  0% {background-position: 0 0;}
  25% {background-position: 48px 12px;}
  50% {background-position: 96px 24px;}
  75% {background-position: 144px 36px;}
  100% {background-position: 192px 48px;}
}

@keyframes seasickscroll {
  0% {background-position: 30% 0%;}
  30% {background-position: 0% 5%;}
  60% {background-position: 15% 7%;}
  90% {background-position: 0% 8%;}
  100% {background-position: 30% 0%;}
}

@keyframes colorshift {
  0% {color: #ff0000;}    /* Red */
  10% {color: #ff8000;}   /* Orange */
  20% {color: #ffff00;}   /* Yellow */
  30% {color: #80ff00;}   /* Light Green */
  40% {color: #00ff00;}   /* Green */
  50% {color: #00ff80;}   /* Light Blue */
  60% {color: #00ffff;}   /* Cyan */
  70% {color: #80ffff;}   /* Light Cyan */
  80% {color: #80ff80;}   /* Light Green */
  90% {color: #ff80ff;}   /* Light Pink */
  100% {color: #ff0000;}  /* Red */
}

.content {
  width: 100vw;
}

body {
  font: 14px "Century Gothic", Futura, sans-serif;
  min-height: 100vh;
  min-width: 95vw;
  margin: 0;
  background-image: url("ebbackground.png");
  background-position: 0% 0%;
}

.scrollingBackground {
  animation-name: bgscroll;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
  
.moveBox {
  background: #101010;
  color: #f1f1f1;
  font-size: 25px;
  padding: 20px;
  max-width: 100vw; /* Ensure boxes are never bigger than 90% of the browser window */
}

.moveBoxHandle {
  width: 100%;
  height: 100%;
  top: -12px;
  right: -12px;

  border-style: solid;
  border-width: 12px;
  border-radius: 16px;
  border-color: #d6d9d8;
  border-image: url('./border.png') 5
}
.foodthingy {
  width: 500px;
}
.oldbox {
  opacity: 0.7;
}

a {
  color: #e86de8;
}

a:hover {
  color: #ffbeff;
}

a:visited {
  color: #5cc2e0;
}

a:visited:hover {
  color: #aed6e2;
}

a.email_link {
  font-size: 19px;
  text-align: center;
  width: 100%;
  display: block;
  animation: colorshift 4s infinite;
}

a:hover.email_link {
  font-size: 19px;
  text-align: center;
  width: 100%;
  display: block;
  animation: colorshift 1s infinite;
}

.email_link {
  font-size: 19px;
  text-align: center;
  width: 100%;
  display: block;
  animation: colorshift 4s infinite;
}
